
<ngx-dialog *ngIf="brokenState()" header="An Error Occurred">
    <p>An error occurred while downloading this version of the app. <br> Please reload and try again.</p>
    <button mat-button dialogFooter color="primary" (click)="updateApp()">Reload</button>
</ngx-dialog>

<ngx-dialog *ngIf="!brokenState() && showDialog()" header="Update App">
    <p>There is a new version of the app. Do you want to update now? <br> Any unsaved changed will be lost.</p>
    <button mat-button dialogFooter (click)="closeDialog()">Later</button>
    <button mat-button dialogFooter color="primary" (click)="updateApp()">Update</button>
</ngx-dialog>
