import {Component, OnInit} from '@angular/core';
import {ServiceWorkerService} from "@juulsgaard/ngx-service-worker";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(public swService: ServiceWorkerService) { }

  async ngOnInit() {
    await this.swService.checkForUpdate();
  }

}
