
<button mat-button *ngIf="!swService.working()" (click)="checkForUpdate()" [disabled]="!this.swService.canCheckForUpdate()">
    <ngx-icon alias="reload"/>
    <span>Check for Updates</span>
</button>

<button mat-button *ngIf="swService.checking() || swService.downloading()" [disabled]="true">
    <ngx-icon alias="loading" class="spin"></ngx-icon>
    <span *ngIf="swService.checking()">Checking for Update</span>
    <span *ngIf="swService.downloading()">Downloading...</span>
</button>

<button mat-button *ngIf="swService.updateReady()" (click)="updateApp()">
    <ngx-icon alias="upgrade"></ngx-icon>
    <span>Update App</span>
</button>
